var reviewsSwiper = new Swiper(".reviews__slider", {
  slidesPerView: 1.1,
  spaceBetween: 10,
  speed: 1000,
  centeredSlides: true,
  loop: true,
  autoplay: {
    delay: 3500,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: ".reviews__nav-btn--next",
    prevEl: ".reviews__nav-btn--prev",
  },
  breakpoints: {
    600: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    900: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    1800: {
      slidesPerView: 5,
      spaceBetween: 30,
    },
  }
});

reviewsSwiper.slideTo(2, 0);
